.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* React Big Calendar Hacks for darkmode */
.rbc-off-range-bg{
  background: black; 
}

.rbc-today{
  background: #0092ff; 
}

.rbc-event{
  background: #31ad79;
}

.rbc-btn-group button{
  color: #0092ff; 
}

.document img {
  max-width: 100%;
}

.document a:link {
  color: lime;
  background-color: transparent;
  text-decoration: none;
}

.document a:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}

.document a:hover {
  color: red;
  background-color: transparent;
}

.document a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
